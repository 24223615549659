import { SYS_ROLE_HEAD_TECHNICIAN, SYS_ROLE_PLANNER, SYS_ROLE_ADMIN,SYS_ROLE_RETAIL } from '../config/constants';
import { getCurrentUserProfile, removeSession } from './Common';

export const logout = () => {
  removeSession();
};

export function logoutAndRedirect() {
  logout();
  window.location.reload();
}

export const getUserRoles = () => {
  const profile = getCurrentUserProfile();
  if (!profile || !profile.roles) return [];
  else return profile.roles;
};

export const isLogin = () => {
  const profile = getCurrentUserProfile();
  if (profile && profile.exp && new Date(profile.exp * 1000) > new Date()) {
    return true;
  }
  logout();
  return false;
};

export const checkRoles = (myRoles) => {
  const userRoles = getUserRoles();
  const hasRole = myRoles.filter((x) => userRoles.includes(x));
  return hasRole.length > 0 ? true : false;
};

export const isHeadTechnician = () => {
  const roles = getUserRoles();
  return roles.includes(SYS_ROLE_HEAD_TECHNICIAN);
};

export const isCanCloseTaskService = () => {
  const roles = getUserRoles();
  return  roles.includes(SYS_ROLE_HEAD_TECHNICIAN) || 
  roles.includes(SYS_ROLE_RETAIL) || 
  roles.includes(SYS_ROLE_ADMIN);
};

export const isPlanner = () => {
  const roles = getUserRoles();
  return roles.includes(SYS_ROLE_PLANNER);
};

export const isAdmin = () => {
  const roles = getUserRoles();
  return roles.includes(SYS_ROLE_ADMIN);
};

export const isRoutePermission = (roles) => {
  return checkRoles(roles);
};
